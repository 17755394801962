import { useRouter as useNextRouter } from 'next/router';
import { useCallback } from 'react';

export const useRouter = () => {
  const router = useNextRouter();

  let asPath = router.asPath.split('?')[0];

  const isLocatedIn = useCallback(
    (path: string) => {
      return path[0] === '/' ? asPath.indexOf(path) === 0 : asPath.includes(path);
    },
    [asPath],
  );

  const LocatedAt = useCallback(
    (path: string) => {
      return asPath === path;
    },
    [asPath],
  );

  return { ...router, isLocatedIn, onlyLocatedIn: LocatedAt };
};
