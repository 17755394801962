import useIsMobile from '@/hooks/useIsMobile';
import { DarkMode, Flex, useDisclosure, useTheme, VStack } from '@chakra-ui/react';
import SidebarNavigation from './SidebarNavigation';
import { HiMenu } from 'react-icons/hi';
import { IconButtonV2 } from '@/components/ui/IconButtonV2';
import React, { useEffect, useRef } from 'react';
import { useColor, useContrastScheme } from '@/chakra/themeUtils';

export const DESKTOP_SIDEBAR_WIDTH = '90px';

const Sidebar = () => {
  const isMobile = useIsMobile();
  const { isOpen, onClose, onToggle } = useDisclosure();
  const theme = useTheme();
  const contrastScheme = useContrastScheme('secondary.main');
  const backgroundColor = useColor('secondary.main');

  const componentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (componentRef.current && !componentRef.current.contains(event.target)) {
        onClose();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [onClose, isMobile, componentRef]);

  if (isMobile) {
    return (
      <DarkMode>
        <Flex
          bg="secondary.main"
          alignItems="center"
          borderRadius="md"
          px={4}
          h="64px"
          w="64px"
          gap={4}
        >
          <IconButtonV2
            color={`${contrastScheme}.800`}
            onClick={onToggle}
            label="Menu"
            variant="ghost"
            fontSize="xl"
            size="lg"
            IconComponent={HiMenu}
          />
        </Flex>
        <Flex
          ref={componentRef}
          w={DESKTOP_SIDEBAR_WIDTH}
          transform={isOpen ? 'translateX(0)' : 'translateX(-100%)'}
          transition="transform 0.2s ease-in-out"
          position="fixed"
          bg={`${backgroundColor}EE`}
          backdropFilter="blur(3px)"
          top={0}
          left={0}
          bottom={0}
          zIndex="modal"
        >
          <SidebarNavigation onClose={onClose} />
        </Flex>
      </DarkMode>
    );
  }

  return (
    <DarkMode>
      <VStack
        zIndex={theme.zIndices.docked}
        position="fixed"
        height="100vh"
        width="100%"
        bg="secondary.main"
        transition="background 0.2s ease-in-out"
        maxWidth={DESKTOP_SIDEBAR_WIDTH}
        alignItems="center"
      >
        <SidebarNavigation />
      </VStack>
    </DarkMode>
  );
};

export default Sidebar;
