import type { AvatarProps, BoxProps } from '@chakra-ui/react';
import { Avatar, HStack, Text } from '@chakra-ui/react';
import React from 'react';

export type StoreNameProps = {
  store: {
    name: string;
    id: string;
  };
  size?: AvatarProps['size'];
  colorScheme?: string;
} & BoxProps;
const StoreName = ({
  store,
  size = 'sm',
  colorScheme = 'primary',
  ...boxProps
}: StoreNameProps) => {
  return (
    <HStack spacing={2} fontWeight="medium" {...boxProps}>
      <Avatar
        color={`${colorScheme}.700`}
        bg={`${colorScheme}.100`}
        size={size}
        name={store.name}
      />
      <Text fontSize="md">{store.name}</Text>
    </HStack>
  );
};

export default StoreName;
