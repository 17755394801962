import { Box, Center, Icon, Link, Spinner, Tooltip, VStack } from '@chakra-ui/react';
import { HiLogout } from 'react-icons/hi';
import { useUserContext } from '@/contexts/user-context';
import UserAvatar from '../user/UserAvatar';
import { IconButtonV2 } from '@/components/ui/IconButtonV2';
import { useContrastScheme } from '@/chakra/themeUtils';
import { useTranslation } from 'react-i18next';
import { PiBellRingingBold, PiBellRingingFill } from 'react-icons/pi';
import { experienceService } from '@/services/experienceService';
import NextLink from 'next/link';
import { useEffect, useState } from 'react';

const SidebarSettings = () => {
  const { user } = useUserContext();
  const contrastScheme = useContrastScheme('secondary.main');
  const { t } = useTranslation(['users', 'common']);
  const [isWidgetOpen, setIsWidgetOpen] = useState(false);

  const handleWidgetClosed = () => {
    setIsWidgetOpen(false);
  };

  useEffect(() => {
    experienceService.listenWidgetClose('on', handleWidgetClosed);
    return () => experienceService.listenWidgetClose('off', handleWidgetClosed);
  }, []);

  if (!user) {
    return (
      <Center>
        <Spinner
          thickness="4px"
          speed="0.7s"
          emptyColor="gray.200"
          color="primary.main"
          size="xl"
        />
      </Center>
    );
  }

  const handleWidgetClick = () => {
    if (isWidgetOpen) {
      experienceService.closeWidget();
    } else {
      experienceService.openWidget();
    }
    setIsWidgetOpen(!isWidgetOpen);
  };

  return (
    <VStack spacing={2} align="center" w="100%">
      {experienceService.isInitialized() && (
        <Box>
          <IconButtonV2
            color={`${contrastScheme}.800`}
            variant="ghost"
            label={t('users:jimo_changelog')}
            IconComponent={isWidgetOpen ? PiBellRingingFill : PiBellRingingBold}
            onClick={handleWidgetClick}
            fontSize="x-large"
            margin={2}
            _hover={{
              transform: 'scale(1.1)',
              transition: 'transform 0.15s',
            }}
          />
        </Box>
      )}
      <Tooltip label={t('users:user_settings')} placement="top">
        <Box>
          <UserAvatar
            size="sm"
            name={`${user.firstName} ${user.lastName}`}
            as={Link}
            cursor="pointer"
            href="/account"
          />
        </Box>
      </Tooltip>
      <Tooltip label={t('common:logout')} hasArrow>
        <Link
          as={NextLink}
          href={'/auth/logout'}
          aria-label={t('common:logout')}
          _hover={{
            bg: `${contrastScheme}.100`,
          }}
          _active={{
            bg: `${contrastScheme}.100`,
          }}
          borderRadius="md"
        >
          <Icon
            display="block"
            fontSize="x-large"
            color={`${contrastScheme}.800`}
            as={HiLogout}
            margin={2}
          />
        </Link>
      </Tooltip>
    </VStack>
  );
};

export default SidebarSettings;
