import * as React from 'react';
import type { As, IconButtonProps as ChakraIconButtonProps } from '@chakra-ui/react';
import { forwardRef, Icon, IconButton as ChakraIconButton, Tooltip } from '@chakra-ui/react';

type IconButtonProps = {
  IconComponent: As;
  label: string;
  href?: string;
  onClick?: () => void;
} & Omit<ChakraIconButtonProps, 'icon' | 'aria-label'>;

export const IconButtonV2 = forwardRef(
  ({ IconComponent, label, ...iconButtonProps }: IconButtonProps, ref) => {
    return (
      <Tooltip label={label} hasArrow>
        <ChakraIconButton
          ref={ref}
          borderRadius="md"
          aria-label={label}
          icon={<Icon as={IconComponent} />}
          {...iconButtonProps}
        />
      </Tooltip>
    );
  },
);
