export enum FormatTypeEnum {
  date = 'date',
  datetime = 'datetime',
  time = 'time',
}

export const formatDate = (date: string, type: FormatTypeEnum = FormatTypeEnum.date) => {
  const formattedDate = Date.parse(date);
  switch (type) {
    case FormatTypeEnum.datetime:
      return new Date(formattedDate).toLocaleString().replace(/ /g, ' à ');
    case FormatTypeEnum.time:
      return new Date(formattedDate).toLocaleTimeString();
    case FormatTypeEnum.date:
    default:
      return new Date(formattedDate).toLocaleDateString();
  }
};

const isoDateFormatRegExp =
  /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(?:\.\d*)?(?:[-+]\d{2}:?\d{2}|Z)?$/;

export const isIsoDateString = (value: unknown): value is string => {
  return typeof value === 'string' && isoDateFormatRegExp.test(value);
};
