import { Text } from '@chakra-ui/react';

export function RequiredIndicator() {
  return (
    <Text as="span" color="red.500">
      {' '}
      *
    </Text>
  );
}
